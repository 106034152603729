import { CacheProvider } from "@emotion/react";
import * as Sentry from "@sentry/remix";
import React, { startTransition, useEffect, useState } from "react";
import { hydrateRoot } from "react-dom/client";

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";

import { version } from "../package.json";
import createEmotionCache, {
  ClientStyleContext,
  defaultCache,
} from "./libs/chakra";
import monitor from "./utils/monitor";

Sentry.init({
  dsn: "https://d661b2dbe3a9b9a30a64561de2a65726@o4507661870759936.ingest.de.sentry.io/4507661887340624",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],

  release: `timable-mainsite@${version}`,
  ...monitor.options,
});

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = useState(defaultCache);

  function reset() {
    setCache(createEmotionCache());
  }

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

function hydrate() {
  startTransition(() => {
    hydrateRoot(
      document.getElementById("root")!,
      // <StrictMode>
      <ClientCacheProvider>
        <RemixBrowser />
      </ClientCacheProvider>
      // </StrictMode>
    );
  });
}

if (typeof requestIdleCallback === "function") {
  requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
